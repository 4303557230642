'use client'

import {
  type RefObject,
  type PropsWithChildren,
  createContext,
  useEffect,
  useRef,
  useState
} from 'react'
import TawkMessengerReact, {
  TawkMessengerMethods
} from '@tawk.to/tawk-messenger-react'

type TawkToContextProps = {
  tawkMessengerRef: RefObject<
    (TawkMessengerReact & TawkMessengerMethods) | null
  >
  isChatLoaded: boolean
}

export const TawkToContext = createContext<TawkToContextProps | undefined>(
  undefined
)

export const TawkToProvider = ({ children }: PropsWithChildren) => {
  const [isChatLoaded, setIsChatLoaded] = useState(false)
  const tawkMessengerRef = useRef<TawkMessengerReact & TawkMessengerMethods>(
    null
  )

  useEffect(() => {
    if (tawkMessengerRef.current) {
      setIsChatLoaded(true)
    }
  }, [])

  const onLoad = () => {
    if (!tawkMessengerRef.current) return

    tawkMessengerRef.current.hideWidget()
  }

  return (
    <TawkToContext.Provider
      value={{
        tawkMessengerRef,
        isChatLoaded
      }}
    >
      <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId="5962960d1dc79b329518d648"
        widgetId="default"
        onLoad={onLoad}
        // @ts-ignore
        customStyle={{
          visibility: {
            desktop: {
              xOffset: '20',
              yOffset: '40',
              position: 'br'
            }
          }
        }}
      />
      {children}
    </TawkToContext.Provider>
  )
}
