import { useContext } from 'react'
import { TawkToContext } from '@/contexts/tawk-to'

export const useTawkTo = () => {
  const ctx = useContext(TawkToContext)

  const maximize = () => {
    ctx?.tawkMessengerRef.current?.maximize()
  }

  const minimize = () => {
    ctx?.tawkMessengerRef.current?.minimize()
  }

  return {
    maximize,
    minimize
  }
}
