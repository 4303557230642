'use client'

import { Text } from '@/components/ui/text'
import { useUnit, useUnitProps } from './use-unit'

export type UnitProps = useUnitProps

const Unit = (props: UnitProps) => {
  const { className, styles, data } = useUnit(props)
  const { name, url, address, neighborhood, city, state } = data

  return (
    <div className={styles.base({ className })}>
      <Text
        as="h4"
        fontSize="body-lg"
        className={styles.title()}
        weight="semibold"
      >
        {name}
      </Text>
      <a className={styles.link()} title={name} href={url} target="_blank">
        <Text as="address" fontSize="body-md" notItalic>
          {address}
          <br /> {neighborhood}, {city} - {state}
        </Text>
      </a>
    </div>
  )
}

export default Unit
