'use client'

import { usePathname, useRouter } from '@/utils/navigation'
import { Locale } from '@/types'
import { i18n } from '@/config/locales'
import LangSwitchItem from './lang-switch.item'

export type LangSwitchSelectProps = {
  className?: string
}

const LangSwitchSelect = ({ className = '' }: LangSwitchSelectProps) => {
  const pathname = usePathname()
  const router = useRouter()

  const changeLocale = (nextLocale: Locale) => {
    router.replace(pathname, { locale: nextLocale })
  }

  return (
    <ul className={className}>
      {i18n.locales.map((locale) => (
        <LangSwitchItem
          key={locale}
          locale={locale}
          changeLocale={changeLocale}
          aria-label={locale.toLocaleUpperCase()}
        />
      ))}
    </ul>
  )
}

export default LangSwitchSelect
