type GetWhatsAppLinkProps = {
  phone?: 'default' | 'share'
  message?: 'default' | 'property' | 'share'
  params?: Record<string, string>
}

export const WHATSAPP = {
  numbers: {
    default: '+55 (83) 98212-0100'
  },
  messages: {
    default:
      'Olá, gostaria de falar com um corretor sobre as opções de imóveis disponíveis. Pode me ajudar?',
    property:
      'Olá, gostaria de saber mais detalhes sobre o imóvel de código: {reference}',
    share: '{url}'
  }
}

export const getWhatsAppLink = ({
  phone = 'default',
  message = 'default',
  params = {}
}: GetWhatsAppLinkProps) => {
  const phoneNumber =
    phone !== 'share' ? WHATSAPP.numbers[phone].replace(/\D/g, '') : null

  const formattedMessage = WHATSAPP.messages[message].replace(
    /{(\w+)}/g,
    (match, p1) => params[p1] || match
  )
  const encodedMessage = encodeURIComponent(formattedMessage)

  if (phone === 'share' && !phoneNumber && encodedMessage) {
    return `https://api.whatsapp.com/send?text=${encodedMessage}`
  }

  return encodedMessage
    ? `https://wa.me/${phoneNumber}?text=${encodedMessage}`
    : `https://wa.me/${phoneNumber}`
}
