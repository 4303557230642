import { Locale } from '@/types'
import { SvgName } from '@/svg'
import { Button } from '@/components/ui/button'

interface LocaleSwitchItemProps {
  className?: string
  locale: Locale
  changeLocale: (locale: Locale) => void
}

const LocaleSwitchItem = ({
  className = '',
  locale,
  changeLocale,
  ...componentProps
}: LocaleSwitchItemProps) => {
  const flag = `flag-${locale}` as SvgName

  return (
    <li className={className}>
      <Button
        onPress={() => changeLocale(locale)}
        color="light"
        size="xl"
        variant="link"
        startIcon={flag}
        {...componentProps}
      />
    </li>
  )
}

export default LocaleSwitchItem
