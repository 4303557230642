import { useTawkTo } from '@/hooks/use-tawk-to'
import { contactWidget } from './contact-widget.variants'

export const useContactWidget = () => {
  const styles = contactWidget()
  const tawkto = useTawkTo()

  return {
    styles,
    tawkto
  }
}
