import { SvgName } from '@/svg'
import { getWhatsAppLink } from '@/utils/whatsapp'

export type ContactsProps = {
  icon: SvgName
  label: keyof Messages['button']
  value: string
  url: string
}

export const contacts: ContactsProps[] = [
  {
    icon: 'phone',
    label: 'phone',
    value: '(83) 2107-0101',
    url: 'tel:8321070101'
  },
  {
    icon: 'social-whatsapp',
    label: 'whatsapp',
    value: '(83) 98212-0100',
    url: getWhatsAppLink({ phone: 'default', message: 'default' })
  },
  {
    icon: 'mail',
    label: 'email',
    value: 'execut@execut.com.br',
    url: 'mailto:execut@execut.com.br'
  }
]
