import { Root, Trigger, Portal, Content, Arrow } from '@radix-ui/react-popover'
import { usePopover, usePopoverProps } from './use-popover'

export type PopoverProps = usePopoverProps

const Popover = (props: PopoverProps) => {
  const { styles, trigger, children, componentProps, contentProps } =
    usePopover(props)

  return (
    <Root {...componentProps}>
      <Trigger asChild>{trigger}</Trigger>
      <Content
        {...contentProps}
        className={styles.base()}
        style={{ zIndex: 99 }}
      >
        <div>{children}</div>
        <Arrow className={styles.arrow()} width={16} height={8} />
      </Content>
    </Root>
  )
}

export default Popover
