import { tv, VariantProps } from '@/utils/tv'

export const unit = tv({
  slots: {
    base: [
      'focus-within:outline-dotted',
      'focus-within:outline-offset-8',
      'focus-within:outline-white/30'
    ],
    link: [],
    title: ['mb-2.5']
  }
})

export type UnitVariantProps = VariantProps<typeof unit>
