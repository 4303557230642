import { tv, VariantProps } from '@/utils/tv'

export const contactCard = tv({
  slots: {
    base: ['group/item', 'w-full', 'flex items-center gap-5', 'select-all'],
    content: ['relative z-20'],
    icon: ['w-6 h-6', 'relative z-20'],
    link: ['absolute inset-0', 'w-full h-full'],
    label: ['mb-1'],
    value: []
  },
  variants: {
    theme: {
      light: {},
      dark: {}
    },
    variant: {
      ghost: {
        base: [
          'transition-colors duration-300',
          'focus-within:outline-dotted',
          'focus-within:outline-offset-6'
        ],
        value: ['transition-colors duration-300']
      },
      card: {
        base: [
          'rounded-md',
          'py-4 px-5',
          'min-w-[280px]',
          'overflow-hidden',
          'card-gradient'
        ],
        icon: ['text-green-500'],
        label: ['text-gray-400'],
        value: ['text-green-500']
      }
    }
  },
  defaultVariants: {
    variant: 'ghost',
    theme: 'light'
  },
  compoundVariants: [
    {
      theme: 'light',
      variant: 'ghost',
      className: {
        base: ['text-white focus-within:outline-white/30 hover:text-green-300'],
        value: ['text-green-100 group-hover/item:text-green-300']
      }
    },
    {
      theme: 'dark',
      variant: 'ghost',
      className: {
        base: [
          'text-gray-400 focus-within:outline-gray-400/30 hover:text-green-500'
        ],
        value: ['text-gray-400 group-hover/item:text-green-500']
      }
    }
  ]
})

export type ContactCardVariantProps = VariantProps<typeof contactCard>
