import { UnitVariantProps, unit } from './unit.variants'

export type useUnitProps = UnitVariantProps & {
  className?: string
  name: string
  address: string
  neighborhood: string
  city: string
  state: string
  url: string
}

export const useUnit = (props: useUnitProps) => {
  const { className = '', ...componentProps } = props

  const styles = unit()

  return {
    className,
    styles,
    data: componentProps
  }
}
