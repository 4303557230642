'use client'

import { SvgName } from '@/svg'
import { contacts } from '@/config/contacts'
import { Text } from '@/components/ui/text'
import { Button } from '@/components/ui/button'
import { Popover } from '@/components/ui/popover'
import { ContactCard } from '@/components/features/contact/contact-card'
import { useContactWidget } from './use-contact-widget'

interface ContentWidgetContentProps {
  title: string
  separator: string
  buttons: Record<string, string>
}

const ContactWidgetContent = ({
  title,
  separator,
  buttons = {}
}: ContentWidgetContentProps) => {
  const { styles, tawkto } = useContactWidget()

  return (
    <Popover
      contentProps={{
        sideOffset: 10,
        align: 'end'
      }}
      trigger={
        <Button
          color="primary"
          startIcon="phone-solid"
          size="xl"
          className={styles.button()}
          rounded
          aria-label={buttons.support}
        />
      }
    >
      <div className={styles.content()}>
        <Text
          as="p"
          weight="medium"
          color="text-gray-600"
          className={styles.title()}
        >
          {title}
        </Text>
        <ul className={styles.list()}>
          {contacts.map(({ icon, label, url, value }) => (
            <li key={`contact-${label}`}>
              <ContactCard
                variant="card"
                icon={icon as SvgName}
                label={buttons[label]}
                value={value}
                url={url}
              />
            </li>
          ))}
        </ul>
        <Text as="span" color="text-gray-400" className={styles.separator()}>
          {separator}
        </Text>
        <Button color="primary" onClick={() => tawkto.maximize()} fullWidth>
          {buttons.chat}
        </Button>
      </div>
    </Popover>
  )
}

export default ContactWidgetContent
