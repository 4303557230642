import { PopoverProps, PopoverContentProps } from '@radix-ui/react-popover'
import { popover } from './popover.variants'

export type usePopoverProps = PopoverProps & {
  className?: string
  trigger: React.ReactNode
  contentProps?: PopoverContentProps
}

export const usePopover = (props: usePopoverProps) => {
  const {
    className = '',
    trigger,
    children,
    contentProps,
    ...componentProps
  } = props

  const styles = popover()

  return {
    className,
    styles,
    trigger,
    children,
    contentProps,
    componentProps
  }
}
