import { SvgName } from '@/svg'
import { NextLink } from '@/utils/navigation'
import { Vector } from '@/components/ui/vector'
import { Text } from '@/components/ui/text'
import { useContactCard, useContactCardProps } from './use-contact-card'

export type ContactCardProps = useContactCardProps

const ContactCard = (props: ContactCardProps) => {
  const { styles, data } = useContactCard(props)
  const { icon, label, value, url } = data

  return (
    <NextLink
      className={styles.base()}
      title={label}
      href={url}
      target="_blank"
    >
      {!!icon && <Vector name={icon as SvgName} className={styles.icon()} />}
      <div className={styles.content()}>
        <Text
          as="p"
          className={styles.label()}
          font="rubik"
          fontSize="tagline-md"
          weight="semibold"
          uppercase
        >
          {label}
        </Text>
        <Text
          as="p"
          className={styles.value()}
          fontSize="body-lg"
          weight="semibold"
        >
          {value}
        </Text>
      </div>
    </NextLink>
  )
}

export default ContactCard
