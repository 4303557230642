import { tv, VariantProps } from '@/utils/tv'

export const popover = tv({
  slots: {
    base: [
      'group',
      'outline-none',
      'bg-white',
      'border border-gray-50',
      'shadow-2xl shadow-gray-800/20',
      'rounded-md',
      'will-change-[transform,opacity]',
      'data-[state=open]:data-[side=top]:animate-slideDownAndFade',
      'data-[state=open]:data-[side=right]:animate-slideLeftAndFade',
      'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade',
      'data-[state=open]:data-[side=left]:animate-slideRightAndFade'
    ],
    arrow: ['fill-white', 'translate-y-[-1px]', 'drop-shadow-sm']
  }
})

export type PopoverVariantProps = VariantProps<typeof popover>

export type PopoverReturnVariantProps = ReturnType<typeof popover>
