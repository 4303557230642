import { useMemo } from 'react'
import { SvgName } from '@/svg'
import { ContactCardVariantProps, contactCard } from './contact-card.variants'

export type useContactCardProps = ContactCardVariantProps & {
  className?: string
  icon?: SvgName
  label: string
  value: string
  url: string
  onClick?: () => void
}

export const useContactCard = (props: useContactCardProps) => {
  const {
    className = '',
    theme = 'light',
    variant = 'ghost',
    onClick = () => {},
    ...componentProps
  } = props

  const styles = useMemo(
    () => contactCard({ theme, variant }),
    [theme, variant]
  )

  return {
    styles,
    data: componentProps
  }
}
