import { tv, VariantProps } from '@/utils/tv'

export const contactWidget = tv({
  slots: {
    content: ['flex flex-col gap-y-4', 'p-6', 'h-auto'],
    button: [
      'fixed bottom-4 right-4 z-100',
      'drop-shadow-sm',
      'shadow-sm',
      'border-green-400/70'
    ],
    title: ['text-center'],
    list: ['flex flex-col gap-y-4'],
    separator: [
      'relative',
      'block',
      'text-center',
      'before:content-[""] after:content-[""]',
      'before:absolute after:absolute',
      'before:top-1/2 after:top-1/2',
      'before:w-2/5 after:w-2/5',
      'before:h-[1px] after:h-[1px]',
      'before:bg-gray-100 after:bg-gray-100',
      'before:left-0 after:right-0'
    ]
  }
})

export type ContactWidgetVariantProps = VariantProps<typeof contactWidget>
